module.exports = () => {
    return {
        depth: 1,
        customiser: 'setup',
        publicPrefix: '$',
        privatePrefix: '_',
        overrides: {},
        extensions: true
    };
};
